<template>
    Live data nog toevoegen!
    <section class="mt-10"><LineChart :chartData="ChartData" :chartOptions="chartOptions" :styles="chartStyles" /></section>
</template>

<script>
    import LineChart from '@/components/chart/LineChart';

    export default {
        components: {
            LineChart,
        },
        created() {
            this.$store.dispatch('energy_data/fetchGasZtpSpotData');
        },
        data() {
            return {
                chartStyles: {
                    height: '400px',
                },
                chartOptions: {
                    animation: { duration: 0 },
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                        display: true,
                    },
                    tooltips: {
                        titleFontSize: 24,
                        bodyFontSize: 20,
                    },
                    scales: {
                        xAxes: [{ gridLines: { display: false }, ticks: { fontSize: 8 } }],
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: false,
                                    callback: function (value) {
                                        return '€' + ' ' + value;
                                    },
                                },
                            },
                        ],
                    },
                },
            };
        },
        computed: {
            ChartData() {
                return {
                    labels: this.$store.getters['energy_data/gas_ztp_spot_data'].gas_labels,
                    datasets: [
                        {
                            label: 'Jaar +1',
                            fill: true,
                            backgroundColor: 'hsla(204, 68%, 58%, 0.1)',
                            pointRadius: 1,
                            borderWidth: 1,
                            borderColor: '#4ca3dd',
                            data: this.$store.getters['energy_data/gas_ztp_spot_data'].gas_values_y1,
                        },
                        {
                            label: 'Jaar +2',
                            fill: true,
                            backgroundColor: 'hsla(168, 55%, 77%, 0.1)',
                            pointRadius: 1,
                            borderWidth: 1,
                            borderColor: '#A3E4D7',
                            data: this.$store.getters['energy_data/gas_ztp_spot_data'].gas_values_y2,
                        },
                        {
                            label: 'Jaar +3',
                            fill: true,
                            backgroundColor: 'hsla(282, 44%, 47%, 0.1)',
                            pointRadius: 1,
                            borderWidth: 1,
                            borderColor: '#8E44AD',
                            data: this.$store.getters['energy_data/gas_ztp_spot_data'].gas_values_y3,
                        },
                    ],
                };
            },
        },
    };
</script>
